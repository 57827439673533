<template>
  <div class="divBox">
    <el-card class="mt14">
      <el-table :data="tableData.data" size="small" highlight-current-row>
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />

        <!-- <el-table-column prop="id" label="ID" min-width="120" /> -->
        <el-table-column prop="nickname" label="用户名称" />
        <el-table-column label="头像" min-width="100">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                style="width: 36px; height: 36px"
                :src="scope.row.avatarUrl||moren"
                :preview-src-list="[scope.row.avatarUrl||moren]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="content" label="评价内容" min-width="200" />

        <el-table-column prop="score" label="	评价图片" min-width="200">
          <template slot-scope="scope">
            <div class="demo-image__preview" v-if="scope.row.img">
              <el-image
                v-for="(item, index) in scope.row.img.split(',')"
                :key="index"
                style="width: 36px; height: 36px; margin-right: 10px"
                :src="item"
                :preview-src-list="[item]"
              />
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="score" label="评分" min-width="150">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-rate
                v-model="scope.row.score"
                disabled
                text-color="#ff9900"
              >
              </el-rate>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { ordersComments,ordersubComments } from "@/api/order/order";
import { getIndex } from "@/utils/other";
import moren from "@/assets/images/moren.png";
export default {
  name: "evaluateTable",
  props: {
    orderId: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      moren,
      title: "新增",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      form: {
        image: [],
        sellPrice: "",
        receivePrice: "",
        sort: "",
      },
      tableData: {
        data: [],
        total: 0,
      },
    };
  },
  created() {
    console.log("123", this.orderId);
    if (this.orderId) {
      console.log("123", this.orderId);
      this.getList();
    }
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    getList() {
      if(this.type==1){
        ordersComments({ orderId: this.orderId })
        .then((res) => {
          this.tableData.data = res.data;
        })
        .catch((res) => {});
      }else{
        console.log('1231')
        ordersubComments({ orderId: this.orderId })
        .then((res) => {
          this.tableData.data = res.data;
        })
        .catch((res) => {});
      }

    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  